<template>
	<div
		:class="[
			'-mx-5 -mt-5 flex',
			'min-h-44 min-[390px]:min-h-[11.5rem] min-[410px]:min-h-48 min-[430px]:min-h-52', // mobile
			'md:min-h-44', // tablet
			'lg:min-h-[220px] xl:min-h-[290px]', // desktop
		]"
	>
		<SliderHero />
	</div>

	<div class="flex flex-col gap-5">
		<div class="-mb-4 pt-2">
			<h1>Monroe Michigan Dispensary</h1>
			<p class="mb-2 text-xs md:text-base">
				Weedys Monroe Michigan Dispensary sells top-shelf recreational cannabis at
				bottom-shelf prices. Visit our Cannabis store for 50% OFF your 1st dispensary order
				(Max $100 saved) upon arrival after verification.
			</p>
			<p class="text-xs md:text-base">
				1118 S Telegraph Rd, Monroe, MI 48161<br />
				Monday-Saturday 9-9 Sunday 10-8<br />
				<AppLink to="tel:8777933397">(877) 793-3397</AppLink>
			</p>

			<p class="-mb-6 mt-2 flex items-center gap-2 text-[.625rem] font-semibold md:text-sm">
				<CreditCardVisa class="w-8 md:w-10" />
				Cash, Debit Card and Visa Credit Cards are accepted.
			</p>
		</div>

		<TheShop />

		<div class="space-y-4">
			<div>
				<h2>Best Dispensary in Monroe, Michigan</h2>
				<p>
					Weedys is the best recreational cannabis dispensary in Monroe Michigan because
					we breed and grow all of our exclusive cannabis strains in house. Our Dispensary
					in Monroe is for cannabis enthusiasts searching for the best consistently
					flavorful, terpene rich, top-shelf weed.
				</p>
			</div>

			<h3 class="!-mb-4">Dispensaries in Monroe Michigan are NOT Created Equal</h3>
			<p>
				Dispensaries in Monroe are often like convenience stores for weed, offering the same
				generic products with no unique features. At Weedys, we stand apart. We breed and
				grow nearly all the strains we sell at our in-house cultivation facility in South
				East Michigan. Known for our terpene-rich flowers, prerolls, and concentrates, we
				are passionate about breeding new strains and discovering rare and exotic terpene
				and cannabinoid profiles. Our genuine passion for the plant sets us apart from other
				Monroe dispensaries.
			</p>
			<br />
			<AppLink to="https://www.weedys.com/strain-library"
				>Check out our complete strain library here.</AppLink
			>
			<ShopCategoryBuckets />

			<div>
				<h3>Curbside Weed</h3>
				<p>
					Because coming inside is a waste of time. No lines, no stress, just consistently
					flavorful weed fast. Order online and pickup from one of our hassle free
					Michigan Dispensaries in 15 minutes or less.
				</p>
			</div>

			<InfoHowToOrder />

			<h3>Most Popular Strains</h3>

			<ShopCardCarousel :items="popularStrainsCardCarousel" />

			<div>
				<h3>Preroll Bundles</h3>
				<p>Save big when you buy prerolls in bulk.</p>
			</div>
		</div>

		<!-- Preroll banner -->
		<div class="-ml-5 h-56 w-[100vw] max-w-[1450px] overflow-hidden bg-cover lg:h-72">
			<img
				src="https://assets.weedys.com/storage/v1/object/public/Images/Preroll%20Banner%201.jpg"
				alt="Weedys preroll bundle packs"
				class="h-full w-full object-cover object-left"
				loading="lazy"
			/>
			<div class="absolute left-5 top-1/4 flex flex-col gap-2">
				<h4 class="w-fit bg-black/70 p-2 text-4xl font-extrabold text-primary drop-shadow">
					Save up to 40%
				</h4>
				<p class="w-fit bg-black/70 p-2 text-white">
					Bundles are available in 5, 10, and 20 packs.
				</p>

				<ButtonOutline
					text="Shop now"
					class="w-48 !border-white text-white"
					@click="goToPrerolls()"
				/>
			</div>
		</div>

		<!-- Yellow circle buckets -->
		<div class="grid grid-cols-3">
			<div class="flex flex-col items-center gap-4 md:flex-row">
				<div
					class="flex h-24 w-24 items-center justify-center rounded-full bg-primary md:h-32 md:w-32"
				>
					<CreditCardsIcon class="h-10 md:h-14" />
				</div>
				<p class="text-center text-sm font-bold text-text md:text-left md:text-lg">
					Credit & Debit Cards Accepted
				</p>
			</div>

			<div class="flex flex-col items-center gap-4 md:flex-row">
				<div
					class="flex h-24 w-24 items-center justify-center rounded-full bg-primary md:h-32 md:w-32"
				>
					<HandHoldingBagIcon class="h-12 pl-2 md:h-16" />
				</div>
				<p class="text-center text-sm font-bold text-text md:text-left md:text-lg">
					Curbside | Buy Weed Fast
				</p>
			</div>

			<div class="flex flex-col items-center gap-4 md:flex-row">
				<div
					class="flex h-24 w-24 items-center justify-center rounded-full bg-primary md:h-32 md:w-32"
				>
					<WQSIcon class="h-14 md:h-20" />
				</div>
				<p class="text-center text-sm font-bold text-text md:text-left md:text-lg">
					Terpenes Over Potency
				</p>
			</div>
		</div>

		<div class="space-y-8">
			<ButtonOutline text="Back to Menu" @click="smoothScrollTo('#shop', 40)">
				<ShoppingBag class="-ml-1 -mt-0.5 w-6" />
			</ButtonOutline>

			<div>
				<div>
					<h3>We grow our weed in-house</h3>
					<span class="absolute left-[9px] top-3.5 rotate-90 text-2xl">)</span>
				</div>
				<p>#antimids #terpchasers #realcraftcannabis</p>

				<AppLink to="/grow-facility-tour"
					>Click here to digitally tour our grow facility.</AppLink
				>
			</div>

			<Banner
				image-url="https://assets.weedys.com/storage/v1/object/public/Images/Cannabis-Grow-1.jpg"
				alt-text="Weedys grow facility"
			/>

			<div>
				<h3>Terpenes Over Potency</h3>
				<p>
					True cannabis lovers know that flavor and aroma are the most important criteria
					for assessing the quality of cannabis. Potency is also an essential factor;
					however, in our opinion, it is NOT the determining factor for making a strain
					selection as the consumer. Unfortunately other dispensaries in Monroe and across
					Michigan relentlessly push the potency over everything narrative. This is
					because they don't have edge and are 100% focused on profit. Weedys is focused
					on the terps.
				</p>
			</div>

			<AppLink to="/learn/terpenes-over-potency"
				>Discover more about terpenes and their importance here.</AppLink
			>

			<ButtonOutline text="Back to Menu" @click="smoothScrollTo('#shop', 40)">
				<ShoppingBag class="-ml-1 -mt-0.5 w-6" />
			</ButtonOutline>

			<div>
				<h3>Best Cannabis Prices in Monroe</h3>
				<p>
					Our flower is different. It's terpene packed. It's beautiful. It stinks to high
					heaven. It's really good. We're growing strains found nowhere else in the world.
					That's because we're breeders. This is what we do. We're also growers, and
					because we're growers, we don't buy weed from other growers. This allows us to
					sell top-shelf weed at bottom-shelf prices. The better you grow, the more you
					get. All the weed we sell at our cannabis store in Monroe is excellent. Still,
					we decided to break it up into three tiers anyway. "Weed," "Good Weed," and
					"Really Good Weed." Our criteria for determining what strains go on which tier
					are different from most dispensaries in Monroe. But hey, we're not in the
					business of being the same.
				</p>
			</div>

			<AppLink to="/cannabis-pricing">Learn more about our pricing tiers here.</AppLink>

			<NuxtLink to="/cannabis-pricing" no-prefetch class="mt-4 flex">
				<img
					src="https://assets.weedys.com/storage/v1/object/public/Images/Flower%20pricing%20tier%20breakdown%20sheet.jpg"
					alt="Weedys flower pricing tiers breakdown"
					class="mx-auto h-[175px] w-[360px] rounded md:h-[358px] md:w-[740px] lg:m-auto lg:h-[465px] lg:w-[960px]"
					loading="lazy"
				/>
			</NuxtLink>

			<div>
				<h3>Really Good Weed Fast</h3>
				<p>
					Half our focus is growing really good weed consistently. The other half is
					focused on getting it into your hands fast. We get it; going into dispensaries
					can be fun the first or second time. But over time, we realize that visiting
					dispensaries in Monroe is a chore. Ordering weed online and picking it up
					curbside is the most convenient way to buy cannabis.
				</p>

				<ul class="mt-2 list-disc pl-4">
					<li>No lines</li>
					<li>No hassle</li>
					<li>Order from the comfort of your home</li>
					<li>Take your time because you're never rushed</li>
					<li>Order online 24/7 pick-up between 9am – 9pm Mon-Sat 10am – 8pm Sun</li>
					<li>Relax in the parking lot watching tik-tok while we run your weed out.</li>
				</ul>
			</div>

			<ButtonOutline text="Back to Menu" @click="smoothScrollTo('#shop', 40)">
				<ShoppingBag class="-ml-1 -mt-0.5 w-6" />
			</ButtonOutline>

			<div>
				<h3>Expand your Knowldge</h3>
				<p>
					Learn more about the Cannabis you consume in our Learning Center. Here are some
					quick links to get you started
				</p>

				<nav class="mt-2 flex flex-col">
					<AppLink to="/learn/what-is-cannabis">What is Cannabis?</AppLink>

					<AppLink to="/learn/cannabis-anatomy">Cannabis Anatomy</AppLink>

					<AppLink to="/learn/cannabinoids">Cannabinoids Explained</AppLink>

					<AppLink to="/learn/cannabis-history">Cannabis History </AppLink>

					<AppLink to="/learn/michigan-dispensary-purchase-limits"
						>Recreational Purchase Limits in Michigan</AppLink
					>
				</nav>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import TheShop from '~/components/TheShop.vue';
import InfoHowToOrder from '../components/info/InfoHowToOrder.vue';
import useSeoContent from '../composables/seoContent';
import Banner from '../components/ui/Banner.vue';
import ShoppingBag from '../components/icons/ShoppingBag.vue';
import ButtonOutline from '../components/ui/ButtonOutline.vue';
import { useProductsStore } from '../stores/productsStore';
import smoothScrollTo from '../utils/smoothScrollTo';
import ShopCardCarousel from '../components/shop/ShopCardCarousel.vue';
import ShopCategoryBuckets from '../components/shop/ShopCategoryBuckets.vue';
import CreditCardVisa from '~/components/icons/CreditCardVisa.vue';
import WQSIcon from '~/components/icons/WQSIcon.vue';
import CreditCardsIcon from '~/components/icons/CreditCardsIcon.vue';
import HandHoldingBagIcon from '~/components/icons/HandHoldingBagIcon.vue';
import SliderHero from '~/components/ui/SliderHero.vue';
import AppLink from '~/components/ui/AppLink.vue';
import type { LocalBusiness, WithContext } from 'schema-dts';

const ProductsStore = useProductsStore();

useServerHead(await useSeoContent(), { tagPriority: -10 });

function goToPrerolls() {
	smoothScrollTo('#shop', 40);
	// Delay page change to avoid footer flashing from rerender
	setTimeout(() => {
		ProductsStore.categoryUserSelection = 'Preroll';
	}, 100); // Roughly match scroll duration
}

const popularStrainsCardCarousel = computed(() => {
	const items = ProductsStore.products.filter((product) =>
		product.productMetadata?.productCategories?.some((category) => category.name === 'Flower'),
	);

	return items.filter((item) => item.productMetadata?.productImages?.length);
});

const schema: WithContext<LocalBusiness> = {
	'@context': 'https://schema.org',
	'@type': 'Store',
	additionalType: 'CannabisDispensary',
	name: 'Weedys Monroe Michigan Dispensary',
	description:
		'Weedys Monroe Michigan Dispensary offers top-shelf recreational cannabis at bottom-shelf prices.',
	address: {
		'@type': 'PostalAddress',
		streetAddress: '1118 S Telegraph Rd',
		addressLocality: 'Monroe',
		addressRegion: 'MI',
		postalCode: '48161',
		addressCountry: 'US',
	},
	telephone: '+18777933397',
	openingHoursSpecification: [
		{
			'@type': 'OpeningHoursSpecification',
			dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
			opens: '09:00',
			closes: '21:00',
		},
		{
			'@type': 'OpeningHoursSpecification',
			dayOfWeek: 'Sunday',
			opens: '10:00',
			closes: '20:00',
		},
	],
	priceRange: '$$',
	geo: {
		'@type': 'GeoCoordinates',
		latitude: '41.91182073071317',
		longitude: '-83.42183589395928',
	},
	url: 'https://www.weedys.com',
	image: 'https://assets.weedys.com/storage/v1/object/public/Images/weedys-logomark-qr-01.jpg',
	paymentAccepted: ['Cash', 'Credit Card', 'Debit Card'],
	currenciesAccepted: 'USD',
	areaServed: [
		{
			'@type': 'City',
			name: 'Monroe',
		},
		{
			'@type': 'City',
			name: 'Toledo',
		},
		{
			'@type': 'State',
			name: 'Michigan',
		},
		{
			'@type': 'State',
			name: 'Ohio',
		},
	],
	sameAs: ['https://www.instagram.com/weedys.official/'],
};

useHead({
	script: [
		{
			type: 'application/ld+json',
			innerHTML: JSON.stringify(schema),
			tagPriority: -5,
		},
	],
});
</script>
